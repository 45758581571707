import * as Yup from "yup";
import "yup-phone"; // Import the "yup-phone" package

// Regular expression for a valid US phone number
const usPhoneNumberRegex = /^(\+?1[-.\s]?)?(?:\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;

export const loginSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required'),
  lastname: Yup.string().required('This field is required'),
  phone: Yup.string()
    .matches(usPhoneNumberRegex, 'Invalid  phone number')
    .required('This field is required'),
  email: Yup.string().email('Invalid email').required('This field is required'),
  tradeId: Yup.string().required('This field is required'),
});

