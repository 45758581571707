const CryptoJS = require('crypto-js');


function encryptData(data, secretKey) {
    // Encrypt with AES using the provided secret key
    const encrypted = CryptoJS.AES.encrypt(data, secretKey).toString();
    return encrypted;
  }

function decryptData(encrypted, secretKey) {
  // Decrypt with AES using the provided secret key
  const decrypted = CryptoJS.AES.decrypt(encrypted, secretKey).toString(CryptoJS.enc.Utf8);
  return decrypted;
}

// Received encrypted data from client



module.exports = { 
    encryptData,
    decryptData
}

// Now `decryptedData` contains the original sensitive information
// Process `decryptedData` as needed...
