
import React from 'react';
import MyForm from './Components/MyForm';



function App() {
  return (
    <div className='bg-gradient-to-tr from-pink-600 via-purple-400 to-purple-600 min-h-screen pt-4 '>
      <MyForm />
    </div>
  );
}

export default App;
