import TextField from "@mui/material/TextField";
import ReactPlayer from "react-player";
import { Box } from "@mui/material";
import Alert from '@mui/material/Alert';
import { useFormik } from "formik";
import { loginSchema } from "../../src/schemas";
import { useState } from "react";
import './Form.css'
import { encryptData } from "../utils/encrypt";



const API_URL = process.env.NODE_ENV === 'production' ? "https://indicatoraccess.kredallino.tech/v1/access" : "http://localhost:3000/v1/access";

const initialValues = {
  name: "",
  lastname: "",
  phone: "",
  email: "",
  tradeId: "",
};

const MyForm = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");


  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: loginSchema,
      onSubmit: async (values, { resetForm }) => {
        values["orderId"] = new URLSearchParams(window.location.search).get("orderId");
        values["payment_id"] = new URLSearchParams(window.location.search).get("payment_id");
        console.log("orderId", values["orderId"])
        if (window.location.host === "pro.ultrasignal.in") {
          const encodedDays = encryptData("2", 'supersecretkey')  // encoded value
          console.log("encoded Days", encodedDays)
          values["days"] = encodedDays
          const encodedName = encryptData("Ultra+Xpro+V.10", "supersecretkey")
          values["indicator_name"] = encodedName
        }

        console.log("form submitted");
        console.log("form values", values);
        try {

          const response = await fetch(API_URL, {
            headers: {
              'Content-Type': 'application/json',
            }, method: "POST", body: JSON.stringify(values)
          })
          const data = await response.json();
          console.log("server response", data);
          setAlertMessage(JSON.stringify(data?.error || data?.message));
          setShowAlert(true);
          if (data?.message === 'ok' || data?.message === 'exists') {
            setAlertMessage("Access has been successfully granted to you. Please log out of your TradingView account, log in again, and apply the indicator to the chart as instructed in the video.");
            const redirect = () => {
              if (window.location.host === "pro.ultrasignal.in") {

                window.location = "http://utrasignal.in/"
              }
              else {
                window.location = "https://kredsignals.com/"
              }

            }
            setTimeout(redirect, 3000)
          }

        }
        catch (err) {
          console.log("Error Occured", err)
          setAlertMessage(err)
          setShowAlert(true)
        }
        // Save values to localStorage
        // localStorage.setItem("formValues", JSON.stringify(values));

        // Reset the form
        resetForm();
      },
    });


  return (


    <div
      className="wrapper w-full max-w-[900px] lg:max-w-[1050px] flex flex-col justify-center
     items-center text-justify bg-[#C7C4BD] px-10 pb-16 pt-8 mx-auto my-2 rounded-[10px] shadow-[0px_0px_24px_0px_#171717]"
    >
      {showAlert && <Alert severity="success" onClose={() => setShowAlert(false)}>{alertMessage}</Alert>}

      <h1 className="form-title text-[#222]  capitalize">Indicator Access</h1>
      <hr className="w-[20rem] h-0.5 mt-6 bg-gradient-to-r from-transparent via-white to-transparent border-0 mb-10" />

      <div className="flex items-center flex-col lg:flex-row w-[100%] gap-14 px-[2rem] ">
        <div className="">
         {window.location.host === "pro.ultrasingnal.in" ? <ReactPlayer
            url={`https://www.youtube.com/watch?v=Cm2TlAErqxE`}
            className="react-player w-full object-contain "
            controls
          /> : <ReactPlayer
          url={`https://youtu.be/wBZimWnwLfc`}
          className="react-player w-full object-contain "
          controls
        /> }
         
        </div>



        <div className="w-full">
          <form onSubmit={handleSubmit} className="">
            <div className="container">
              <div className="flex justify-center flex-col md:flex-row  gap-4 text-center ">
                <div className="flex flex-col items-start w-full md:w-1/2">
                  <TextField

                    label="First Name"
                    variant="standard"
                    color="secondary"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // fullWidth
                    sx={{ mb: 1, width: "100%" }}
                  />
                  {errors.name && touched.name && (
                    <p className="text-red-500  pt-0">{errors.name}</p>
                  )}
                </div>

                <div className="flex flex-col items-start w-full md:w-1/2">
                  <TextField

                    label="Last Name"
                    variant="standard"
                    name="lastname"
                    value={values.lastname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // fullWidth
                    sx={{ mb: 1, width: "100%" }}
                  />
                  {errors.lastname && touched.lastname && (
                    <p className="text-red-600  pt-0">{errors.lastname}</p>
                  )}
                </div>
              </div>
              <div className="w-full md:w-1/2 mt-2.5">
                <TextField

                  className="input"
                  label="Phone Number"
                  variant="standard"
                  color="secondary"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // fullWidth
                  sx={{ mb: 1, width: "100%" }}
                />
                {errors.phone && touched.phone && (
                  <p className="text-red-500  pt-0">{errors.phone}</p>
                )}
              </div>
              <div className="mt-2.5">
                <TextField

                  className="input"
                  label="Email"
                  variant="standard"

                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // fullWidth
                  sx={{ mb: 1, width: "100%" }}
                />
                {errors.email && touched.email && (
                  <p className="text-red-600  pt-0">{errors.email}</p>
                )}
              </div>
              <div className="w-full md:w-1/2 mt-2.5">
                <TextField

                  classes={'input'}
                  label="TradingView Id"
                  variant="standard"
                  color="primary"
                  name="tradeId"
                  // helperText="Should be in String Format"
                  value={values.tradeId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // fullWidth
                  sx={{ mb: 1, width: "100%" }}
                />
                {errors.tradeId && touched.tradeId && (
                  <p className="text-red-500  pt-0">{errors.tradeId}</p>
                )}
              </div>
              <button
                onClick={handleSubmit}
                type="submit"
                className="p-2  w-full  rounded-lg mt-4 shadow-[0px_0px_18px_-6px_#353541]
                focus:outline-none focus:border-gray-600 bg-[#181822] text-[#eee] text-[1.2rem]"
              >
                Submit
              </button>
            </div>

          </form>
        </div>
      </div>
    </div>
  );
};

export default MyForm;
